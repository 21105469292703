import React, { useState, useEffect } from 'react';
import {
  Modal,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  CircularProgress,
  Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

const LedgerModal = ({ open, onClose, ledgerData, customerName }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (ledgerData) {
      setLoading(false);
    }
  }, [ledgerData]);

  if (!open) return null;
  if (loading) return <CircularProgress />;

  const { startingBalance, ledgerDetails, endingBalance } = ledgerData;

  const handlePrint = () => {
    const printWindow = window.open('', '_blank');
    printWindow.document.write(`
      <html>
        <head>
          <title>Kichloo & Co.</title>
          <style>
            body {
              font-family: 'Consolas', 'Courier New', monospace;
              
            }
            table {
              width: 100%;
              border-collapse: collapse;
              margin-bottom: 1rem;
            }
            th, td {
              border: 1px solid #ccc;
              padding: 0.5rem;
              text-align: left;
            }
            th {
              background-color: #f0f0f0;
            }
          </style>
        </head>
        <body>
          <h2>Ledger Details : ${customerName.toUpperCase()}</h2>
          <p><strong>Opening Balance:</strong> ₹${startingBalance.toFixed(2)}</p>
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Description</th>
                <th>Amount (₹)</th>
                <th>Balance After Entry (₹)</th>
              </tr>
            </thead>
            <tbody>
              ${ledgerDetails.map((entry, index) => `
                <tr>
                  <td>${new Date(entry.date).toLocaleDateString('en-GB')}</td>
                  <td>${entry.description}</td>
                  <td>₹${entry.amount.toFixed(2)}</td>
                  <td>₹${entry.balanceAfterEntry.toFixed(2)}</td>
                </tr>
              `).join('')}
            </tbody>
          </table>
          <p><strong>Ending Balance:</strong> ₹${endingBalance.toFixed(2)}</p>
          <script>
            window.focus();
            window.print();
            window.close();
          </script>
        </body>
      </html>
    `);
  };

  const handleSaveAsPDF = () => {
    const doc = new jsPDF();
    doc.setFont('courier');
    doc.text(`Ledger Details for ${customerName.toUpperCase()}`, 14, 20);
    doc.text(`Opening Balance: ${startingBalance.toFixed(2)}`, 14, 30);
  
    const tableData = ledgerDetails.map(entry => [
      new Date(entry.date).toLocaleDateString('en-GB'),
      entry.description,
      `${entry.amount.toFixed(2)}`,
      `${entry.balanceAfterEntry.toFixed(2)}`
    ]);
  
    doc.autoTable({
      startY: 40,
      head: [['Date', 'Description', 'Amount', 'Balance After Entry']],
      body: tableData,
      theme: 'striped',
      margin: { top: 10 },
      styles: {
        font: 'courier',
        cellPadding: 3,
        fontSize: 10,
        overflow: 'linebreak',
        valign: 'middle',
        halign: 'left',
      },
      columnStyles: {
        0: { cellWidth: 30 },
        1: { cellWidth: 80 },
        2: { cellWidth: 30},
        3: { cellWidth: 60, halign: 'center' },
      },
    });
  
    doc.text(`Ending Balance: Rs. ${endingBalance.toFixed(2)}`, 14, doc.autoTable.previous.finalY + 10);
    
    doc.save(`${customerName}_ledger.pdf`);
  };
  

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          width: '80%',
          margin: 'auto',
          marginTop: '5%',
          padding: 2,
          backgroundColor: 'white',
          boxShadow: 24,
          position: 'relative',
          height: '80vh',
          overflowY: 'auto',
        }}
      >
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>

        <Typography variant="h6" component="h2" sx={{ marginBottom: 2 }}>
          Ledger Details for {customerName.toUpperCase()}
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          <strong>Opening Balance:</strong> ₹{startingBalance.toFixed(2)}
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Description</TableCell>
                <TableCell align="right">Amount (₹)</TableCell>
                <TableCell align="right">Balance After Entry (₹)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ledgerDetails.map((entry, index) => (
                <TableRow key={index}>
                  <TableCell>{new Date(entry.date).toLocaleDateString('en-GB')}</TableCell>
                  <TableCell>{entry.description}</TableCell>
                  <TableCell align="right" sx={{ color: entry.amount < 0 ? "red" : "green" }}>₹{entry.amount.toFixed(2)}</TableCell>
                  <TableCell align="right">₹{entry.balanceAfterEntry.toFixed(2)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Typography variant="body1" sx={{ marginTop: 2 }}>
          <strong>Ending Balance:</strong> ₹{endingBalance.toFixed(2)}
        </Typography>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
          {
            window.innerWidth<=600?(""):(<Button
              variant="contained"
              color="primary"
              onClick={handlePrint}
            >
              Print
            </Button>)
          }
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSaveAsPDF}
          >
            Save as PDF
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default LedgerModal;
