import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import AddIcon from '@mui/icons-material/Add';
import apiClient from '../services/apiClient';
import { API_BASE_URL } from '../constants';
import { toast } from 'react-toastify';
import { GridDeleteIcon } from '@mui/x-data-grid';
import AddVendor from './AddVendor';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';

const AddInventory = ({ onClose, onAdd }) => {
  const [vendorId, setVendorId] = useState('');
  const [items, setItems] = useState([{ itemName: '', quantityReceived: '', remainingStock: '' }]);
  const [dateReceived, setDateReceived] = useState('');
  const [vendors, setVendors] = useState([]);
  const [itemOptions, setItemOptions] = useState([]);
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchVendors = async () => {
    try {
      const response = await apiClient.get(`${API_BASE_URL}/vendors`);
      setVendors(response.data);
    } catch (error) {
      console.error("Error fetching vendors:", error);
      toast.error('Error fetching vendors.');
    }
  };
  useEffect(() => {
    

    fetchVendors();
    getVegetables();
  }, []);

  const getVegetables = async () => {
    try {
      const response = await apiClient.get(`${API_BASE_URL}/vegetables/getVeg`);
      const vegetables = response.data.map((vegetable) => vegetable.name);
      setItemOptions(vegetables);
    } catch (error) {
      console.error("Error fetching vegetables:", error);
    }
  };
  const handleItemChange = (index, field, value) => {
    const newItems = [...items];
    newItems[index][field] = value;
    
    // Ensure remainingStock is updated when quantityReceived changes
    if (field === 'quantityReceived') {
      newItems[index].remainingStock = value;
    }
    
    setItems(newItems);
  };
  

  const handleAddItem = () => {
    setItems([...items, { itemName: '', quantityReceived: '', remainingStock: '' }]);
  };

  const handleDeleteItem = (index) => {
    const newItems = items.filter((_, i) => i !== index);
    setItems(newItems);
  };

  const handleItemNameBlur = (index) => {
    const itemName = items[index]?.itemName || '';
  
    console.log('Item name before match:', itemName); // Debugging
  
    // Find the option that includes the itemName as a substring (case-insensitive)
    const matchedOption = itemOptions.find((option) =>
      option.toLowerCase().includes(itemName.toLowerCase())
    );
  
    console.log('Matched option:', matchedOption); // Debugging
  
    if (matchedOption) {
      const newItems = [...items];
      newItems[index].itemName = matchedOption; // Update itemName in state to matchedOption
      setItems(newItems);
    } else {
      // If no match, keep the user-typed value
      // You can also add a new item to the itemOptions array if you want to allow new items
    }
  };
  
  const handleAddVendor = (vendor) => {
    setVendors((prevVendors) => [...prevVendors, vendor]);
    fetchVendors(); // Ensure the list is up-to-date
    setAddModalOpen(false);
  };

  useEffect(() => {
    console.log('Item options:', itemOptions); // Add this to check the content of itemOptions
  }, [itemOptions]);

  const handleVendorChange = (event, newValue) => {
    setVendorId(newValue ? newValue._id : '');
  };

  const handleVendorBlur = (event) => {
    const inputValue = event.target.value;
    const matchedVendor = vendors.find((vendor) =>
      vendor.name.toLowerCase().startsWith(inputValue.toLowerCase())
    );
    if (matchedVendor) {
      setVendorId(matchedVendor._id);
    } else {
      setVendorId('');
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const inventoryPayload = {
        vendorId,
        dateReceived: dateReceived || new Date().toISOString(),
        items: items.map(({ itemName, quantityReceived, remainingStock }) => ({
          itemName,
          quantityReceived,
          remainingStock,
        })),
      };

      const response = await apiClient.post(`${API_BASE_URL}/inventory/add`, inventoryPayload);
      onAdd(response.data);
      toast.success('Items added successfully!');
      onClose(); // Close dialog after adding
    } catch (error) {
      console.error("Error adding inventory items:", error);
      toast.error('Error adding inventory items.');
    }
    finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={true} onClose={onClose} maxWidth="md" fullWidth>
      
      <DialogContent>
      <Box sx={{ display: 'flex', justifyContent: 'space-between',height:"50px", mb:2 }}>
      <DialogTitle>Add Inventory Items</DialogTitle>
      {window.innerWidth<=600?(<IconButton onClick={() => setAddModalOpen(true)} sx={{color:"green"}}>
       <PersonAddAltIcon/>
      </IconButton>):( <Button
          variant="contained"
          color="primary"
          onClick={() => setAddModalOpen(true)}
        >
          Add New Vendor
        </Button>)}
       
      </Box>
        <Box sx={{ mb: 2 }}>
       
          <Autocomplete
            freeSolo
            autoHighlight
            options={vendors}
            getOptionLabel={(option) => option.name}
            value={vendors.find((vendor) => vendor._id === vendorId) || null}
            onChange={handleVendorChange}
            onBlur={handleVendorBlur}
            filterOptions={(options, { inputValue }) => {
              return options.filter((option) =>
                option.name.toLowerCase().includes(inputValue.toLowerCase())
              );
            }}
            isOptionEqualToValue={(option, value) =>
              option._id === value._id
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Vendor"
                fullWidth
                margin="dense"
                variant="outlined"
              />
            )}
          />
        </Box>

        {/* Single Date Field for All Items */}
        <TextField
          label="Date Received"
          type="date"
          margin="dense"
          value={dateReceived}
          variant="outlined"
          fullWidth
          InputLabelProps={{ shrink: true }}
          onChange={(e) => setDateReceived(e.target.value)}
        />

        <Grid container spacing={2} mt={2}>
          {items.map((item, index) => (
            <Grid item xs={12} key={index}>
              <Grid container spacing={2}>
                {/* Item Name (4 Grid Units) */}
                <Grid item md={4} xs={12}>
                <Autocomplete
  freeSolo
  autoHighlight
  options={itemOptions || []} // Ensure itemOptions is always an array
  value={item.itemName || ''} // Ensure itemName is a string
  onChange={(event, newValue) => handleItemChange(index, 'itemName', newValue)}
  onBlur={() => handleItemNameBlur(index)}
  filterOptions={(options, { inputValue }) => {
    return options.filter((option) =>
      option.toLowerCase().includes(inputValue.toLowerCase())
    );
  }}
  isOptionEqualToValue={(option, value) =>
    option.toLowerCase() === value.toLowerCase()
  }
  renderInput={(params) => (
    <TextField
      {...params}
      label="Item Name"
      fullWidth
      margin="normal"
      sx={{ marginTop: "2px" }}
    />
  )}
/>


                </Grid>
                {/* Quantity Received (4 Grid Units) */}
                <Grid item md={4} xs={5}>
                  <TextField
                    label="Quantity Received"
                    margin="dense"
                    value={item.quantityReceived}
                    variant="outlined"
                    fullWidth
                    onChange={(e) => handleItemChange(index, 'quantityReceived', e.target.value)}
                  />
                </Grid>
                {/* Remaining Stock (4 Grid Units) */}
                <Grid item md={4} xs={5}>
                  <TextField
                    label="Remaining Stock"
                    margin="dense"
                    value={item.remainingStock}
                    variant="outlined"
                    fullWidth
                    disabled
                  />
                </Grid>
                {/* Delete Button (2 Grid Units) */}
                <Grid item md={2} xs={2}>
                  {window.innerWidth <= 600 ? (
                    <IconButton
                      sx={{ marginTop: "10px", color: "red" }}
                      color="primary"
                      aria-label="delete-item"
                      onClick={() => handleDeleteItem(index)}
                    >
                      <GridDeleteIcon />
                    </IconButton>
                  ) : (
                    <Button
                      onClick={() => handleDeleteItem(index)}
                      color="secondary"
                      variant="outlined"
                      fullWidth
                      startIcon={<GridDeleteIcon />}
                      sx={{ color: "red" }}
                    >
                      Delete
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>

        <Button
          onClick={handleAddItem}
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
          fullWidth
          startIcon={<AddIcon />}
        >
          Add Another Item
        </Button>
        {isAddModalOpen && (
    <AddVendor
      onClose={() => setAddModalOpen(false)}
      onAdd={handleAddVendor}
    />
  )}
      </DialogContent>
      <DialogActions>
      <Button onClick={onClose} color="primary">Cancel</Button>
      <Button 
        onClick={handleSubmit} 
        color="primary"
        disabled={loading} // Disable button while loading
      >
        {loading ? "Adding..." : 'Add Items'}
      </Button>
    </DialogActions>
    </Dialog>
    
  );
  
};

export default AddInventory;
