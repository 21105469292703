import React, { useEffect, useState } from "react";
import AddVendor from "./AddVendor"; // Import the AddVendor component
import { API_BASE_URL } from "../constants";
import apiClient from "../services/apiClient";
import {
  Box,
  Button,
  Typography,
  TextField,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import { DataGrid, GridViewHeadlineIcon } from "@mui/x-data-grid";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import WatakModal from "./WatakModal";
import AddIcon from "@mui/icons-material/Add";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { useNavigate } from "react-router-dom";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import { Commet, FourSquare } from "react-loading-indicators";
import { DeleteForeverOutlined } from "@mui/icons-material";

const VendorList = () => {
  const [vendors, setVendors] = useState([]);
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [isWatakModalOpen, setWatakModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredVendors, setFilteredVendors] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [loadingOndelete, setLoadingOndelete] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    fetchVendors();
  }, []);

  useEffect(() => {
    if (searchQuery === "") {
      setFilteredVendors(vendors);
    } else {
      const filtered = vendors.filter(
        (vendor) =>
          vendor &&
          vendor.name &&
          vendor.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredVendors(filtered);
    }
  }, [searchQuery, vendors]);

  const fetchVendors = async () => {
    setLoading(true);
    try {
      const response = await apiClient.get(`${API_BASE_URL}/vendors`);
      const vendorsData = response.data.vendors || response.data;
      // Filter out any invalid vendors
      const validVendors = vendorsData.filter(
        (vendor) => vendor && vendor.name
      );
      setVendors(validVendors);
      setFilteredVendors(validVendors);
    } catch (error) {
      console.error("Error fetching vendors:", error);
      toast.error("Error fetching vendors.");
    } finally {
      setLoading(false);
    }
  };

  const handleAddVendor = (vendor) => {
    console.log("Received vendor:", vendor);
    if (vendor && vendor._id) {
      setVendors((prevVendors) => [...prevVendors, vendor]);
      fetchVendors();
      setAddModalOpen(false);
    } else {
      console.error("Invalid vendor object:", vendor);
      toast.error("Invalid vendor data.");
    }
  };

  const handleOpenDeleteDialog = (row) => {
    setSelectedRow(row);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setSelectedRow(null);
  };

  const handleConfirmDelete = async () => {
    setLoadingOndelete(true);
    const vendorId = selectedRow;
    try {
      await apiClient.delete(`${API_BASE_URL}/vendors/${vendorId}`);
      setVendors((prev) => prev.filter((vendor) => vendor._id !== vendorId));
      setFilteredVendors((prev) =>
        prev.filter((vendor) => vendor._id !== vendorId)
      );
      toast.success("Vendor deleted successfully!");
      setSearchQuery("");
    } catch (error) {
      console.error("Error deleting vendor:", error);
      toast.error("Error deleting vendor.");
    } finally {
      setLoadingOndelete(false);
      handleCloseDeleteDialog();
    }
  };

  const handleOpenWatakModal = (id, ledgerBalance, name,type) => {
    setSelectedCustomer({ id, ledgerBalance, name ,type});
    setWatakModalOpen(true);
  };

  const handleCloseWatakModal = () => {
    setWatakModalOpen(false);
  };

  const handleAddWatak = async (invoice) => {
    const vendorId= invoice.customer.id
    
      try {
      
        // Make a POST request to add the invoice
        const response = await apiClient.post(
          `${API_BASE_URL}/vendor/${vendorId}`,
          {
            invoice
          }
        );
       
        toast.success("Watak Created successfully");
      
      } catch (error) {
        console.error("Error adding Watak:", error);
        return Promise.reject(error); // Reject the promise on error
      }
    };

  const columns = [
    {
      field: "serialNumber",
      headerName: "S.No",
      width: 70,
      renderCell: (params) => (
        <Typography>{params.row.serialNumber}</Typography>
      ),
    },
    { field: "name", headerName: "Name", width: 150 },
    { field: "ledgerBalance", headerName: "Ledger Balance", width: 80 },
    { field: "type", headerName: "Type", width: 150 },
    {
      field: "actions",
      headerName: "Actions",
      width: 300,
      renderCell: (params) => (
        <div>
          {window.innerWidth <= 600 ? (
            <>
              <IconButton
                color="primary"
                aria-label="add-watak"
                onClick={() =>
                  handleOpenWatakModal(
                    params.row._id,
                    params.row.ledgerBalance,
                    params.row.name,
                    params.row.type
                  )
                }
                style={{ marginRight: "8px" }}
              >
                <ReceiptIcon />
              </IconButton>
              <IconButton
                color="primary"
                aria-label="delete-vendor"
                onClick={() => handleOpenDeleteDialog(params.row._id)}
                style={{ marginRight: "8px", color: "red" }}
              >
                <DeleteForeverOutlined />
              </IconButton>
            </>
          ) : (
            <>
              <Button
                variant="outlined"
                color="primary"
                style={{ marginRight: "8px" }}
                startIcon={<AddIcon />}
                onClick={() =>
                  handleOpenWatakModal(
                    params.row._id,
                    params.row.ledgerBalance,
                    params.row.name,
                    params.row.type

                  )
                }
              >
                Add Watak
              </Button>
              <Button
                variant="outlined"
                sx={{ backgroundColor: "red", color: "white" }}
                startIcon={<DeleteForeverOutlined />}
                onClick={() => handleOpenDeleteDialog(params.row._id)}
              >
                Delete
              </Button>
            </>
          )}
        </div>
      ),
    },
  ];

  const rows = filteredVendors
    .map((vendor, index) => {
      if (!vendor || !vendor._id) {
        console.error("Invalid vendor object:", vendor);
        return null;
      }
      return {
        id: vendor._id,
        serialNumber: index + 1,
        ...vendor,
      };
    })
    .filter((row) => row !== null);

  return (
    <Box sx={{ p: 2 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        {window.innerWidth <= 600 ? (
          <Typography variant="h2" sx={{ fontSize: "13px" }}>
            Vendor List
          </Typography>
        ) : (
          <Typography variant="h4">Vendor List</Typography>
        )}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {window.innerWidth <= 600 ? (
            <>
              <IconButton
                color="primary"
                aria-label="add-vendor"
                onClick={() => setAddModalOpen(true)}
              >
                <PersonAddAltIcon />
              </IconButton>
              <IconButton
                color="primary"
                aria-label="view-wataks"
                onClick={() => navigate("/viewTodaysWataks")}
              >
                <GridViewHeadlineIcon />
              </IconButton>
            </>
          ) : (
            <>
              <Button
                variant="contained"
                color="primary"
                style={{ marginRight: "8px" }}
                startIcon={<PersonAddAltIcon />}
                onClick={() => setAddModalOpen(true)}
              >
                Add Vendor
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{ marginRight: "8px" }}
                startIcon={<GridViewHeadlineIcon />}
                onClick={() => navigate("/viewTodaysWataks")}
              >
                View Wataks
              </Button>
            </>
          )}
          <TextField
            variant="outlined"
            placeholder="Search Vendors"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </Box>
      </Box>
      <Box height={500} width="100%">
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="400px"
          >
            <FourSquare
              color={["#33CCCC", "#33CC36", "#B8CC33", "#FCCA00"]}
              size="small"
              text="Loading..."
              textColor={["#33CCCC", "#33CC36", "#B8CC33", "#FCCA00"]}
            />
          </Box>
        ) : (
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[5, 10, 20]}
            autoHeight
            disableSelectionOnClick
            localeText={{ noRowsLabel: "It's lonely here." }}
          />
        )}
      </Box>
      {isAddModalOpen && (
        <AddVendor
          onClose={() => setAddModalOpen(false)}
          onAdd={handleAddVendor}
        />
      )}
      {isWatakModalOpen && (
       <WatakModal
       open={isWatakModalOpen}
       handleClose={handleCloseWatakModal}
       handleAddWatak={handleAddWatak}
       customer={selectedCustomer}
     />
      )}
      {openDeleteDialog && (
        <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
          <DialogTitle>Delete Vendor</DialogTitle>
          <DialogContent>
            <Typography>
              Are you sure you want to delete this vendor?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
            <Button
              onClick={handleConfirmDelete}
              color="error"
              disabled={loadingOndelete}
            >
              {loadingOndelete ? "Deleting..." : "Delete"}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <ToastContainer />
    </Box>
  );
};

export default VendorList;
